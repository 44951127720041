import React, {
  lazy,
  useEffect,
  useRef,
  useState,
  Suspense,
  useCallback,
} from 'react';
import { RiArrowRightDoubleFill } from 'react-icons/ri';
import './productDetail.scss';
import SimilarProduct from './similar-product';
import Slider from './slider';
import ProductDescription from './product-description';
import RightComponent from './right-component';
import {
  Box,
  Button,
  Flex,
  Select,
  Skeleton,
  Spinner,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  fetchProductDefaultDetails,
  fetchProductDetails,
  fetchProductFromSameSeller,
  fetchReportCategores,
  reportProductHandler,
  setProductDetails,
  setProductFromSameSeller,
  setProductSkuId,
} from '../../redux/productSlice';
import NumberGroupInput from '../../components/number-group-input';
import SimilarProducts from './similar-product';
import Rating from './rating';
import ReviewCard from './reviews';
import { setOnLoginOpen } from '../../redux/homeSlice';
import Modal from '../../components/modal';
import { useQuery } from '../../hooks/useQuery';
import isEmpty from 'is-empty';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import ReportProductModal from './report-product';
import RecommendationFromSameSeller from '../../components/home/sameSallerRecommendations';
import { productFromSameSeller } from '../../services/product-service';

const Logistics = lazy(() => import('./logistics'));
let tt;
let count = 0;

const isProductDetailsEmpty = (details) => {
  return !details || !details.images?.length || !details.thumb_images?.length;
};

const ProductDetail = ({
  fromHome,
  fromListing,
  onClose,
  cart_sku_id,
  fromCart,
}) => {
  const toast = useToast();
  const {
    isOpen: isOpenReportProduct,
    onOpen: onOpenReportProduct,
    onClose: onCloseReportProduct,
  } = useDisclosure();
  const {
    isOpen: isOpenCart,
    onOpen: onOpenCart,
    onClose: onCloseCart,
  } = useDisclosure();
  const {
    isOpen: isOpenCartMobile,
    onOpen: onOpenCartMobile,
    onClose: onCloseCartMobile,
  } = useDisclosure();
  const dispatch = useDispatch();
  const { id, sku } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const [isSmallerThan991] = useMediaQuery('(max-width: 991px)');
  const [isCount, setIsCount] = useState(1);

  const [isExpanded, setIsExpanded] = useState(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const [loading, setLoading] = useState(false);

  const contentRef = useRef(null);
  const {
    productDetails,
    productId,
    sku_id,
    isProductLoading,
    reportCategores,
  } = useSelector((state) => state.product);

  const { cartDetails } = useSelector((state) => state.cart);
  const { userData } = useSelector((state) => state.user);
  const { guestInfo } = useSelector((state) => state.guest);
  const [reason, setReason] = useState('');
  const product = productDetails?.products;
  const [isProductNotFound, setIsProductNotFound] = useState(false);
  const storeName = useSelector((state) => state.home?.store);
  
  const fetchProductDetailsHandler = useCallback(async () => {
    if ((id || productId) && (userData?.id || guestInfo?.id)) {
      // Reset the error state before making the API call
      setIsProductNotFound(false);

      try {
        dispatch(
          fetchProductDefaultDetails(
            id || productId,
            searchParams.get('sku_id') || sku_id || cart_sku_id,
            userData?.id || guestInfo?.id
          )
        );
        const response = await dispatch(
          fetchProductDetails(
            id || productId,
            searchParams.get('sku_id') || sku_id || cart_sku_id,
            userData?.id || guestInfo?.id
          )
        );

        console.log('API Response:', response);

        // If 404 is returned, set isProductNotFound to true
        if (response?.status === 404) {
          console.log(
            'Product not found (404). Setting isProductNotFound to true.'
          );
          setIsProductNotFound(true);
        } else {
          console.log('Product found. isProductNotFound remains false.');
        }
      } catch (error) {
        console.error('Error fetching product details:', error);
        // Set isProductNotFound to true if an error occurs (failure condition)
        setIsProductNotFound(true);
      }
    }
    count++;
  }, [
    cart_sku_id,
    dispatch,
    guestInfo?.id,
    id,
    productId,
    searchParams,
    sku_id,
    userData?.id,
  ]);

  useEffect(() => {
    if (tt) {
      clearTimeout(tt);
    }
    dispatch(setProductDetails({ images: [], thumb_images: [] }));
    tt = setTimeout(() => {
      if (count === 0) {
        fetchProductDetailsHandler();
        dispatch(fetchReportCategores());
      }
    }, 500);

    return () => {
      clearTimeout(tt);
    };
  }, [fetchProductDetailsHandler, sku_id]);

  useEffect(() => {
    if (!isEmpty(productDetails)) {
      count = 0;
    }
    const checkContentHeight = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.scrollHeight;
        setShowViewMore(contentHeight > 1200);
      }
    };

    if (product?.description) {
      requestAnimationFrame(checkContentHeight);
    }

    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(checkContentHeight);
    });

    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [product?.description, productDetails]);

  const handleToggle = () => {
    setLoading(true);
    setTimeout(() => {
      setIsExpanded(!isExpanded);
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (
      cartDetails?.data &&
      cartDetails?.data?.length > 0 &&
      productDetails?.products?.id
    ) {
      const foundItem = cartDetails?.data?.find(
        (el) => el?.product_id === productDetails?.products?.id
      );
      if (foundItem) {
        setIsCount(foundItem.quantity);
      } else {
        setIsCount(1);
      }
    }
  }, [cartDetails?.data, productDetails?.products?.id]);

  useEffect(() => {
    const userId = userData?.id || guestInfo?.id;
    if (userId) {
    dispatch(fetchProductFromSameSeller(id, sku, userId, ''));
    }
  }, []);

  if (isProductNotFound) {
    return (
      <Flex
        justify={'center'}
        align={'center'}
        minHeight={'calc(100vh - 433px)'}
        flexDirection='column'
        textAlign='center'
      >
        <Text
          fontSize={'18px'}
          color={'gray.600'}
          maxWidth={'500px'}
          lineHeight='1.5'
        >
          We are sorry for the inconvenience,
          <br /> unfortunately, this product is currently not available.
        </Text>
      </Flex>
    );
  }

  // const cartHandler = (data) => {
  //   onOpenCart();
  // };

  const cartHandler = (data) => {
    dispatch(setProductSkuId(data?.sku_id));
    // setProductDat(data);
    if (isSmallerThan767) {
      onOpenCartMobile();
    } else {
      onOpenCart();
    }
  };

  return (
    <>
      <div className='container'>
        <div className='product-container'>
          <Flex
            gap={'1.25rem'}
            p={isSmallerThan991 ? '5px' : '20px'}
            w={'100%'}
            flexDir={{ base: 'column', lg: 'row' }}
            minH='50vh'
          >
            <Box flex={1} w={isSmallerThan991 ? '100%' : '70%'}>
              <Flex gap={'20px'} flexDir={isSmallerThan991 ? 'column' : 'row'}>
                <Flex
                  gap={'1.87rem'}
                  width={{ base: '100%', lg: '360px' }}
                  flexDir={'column'}
                >
                  <div className='swiper'>
                    {isProductLoading ? (
                      <Skeleton height={'360px'} width={'360px'} />
                    ) : (
                      <Slider
                        images={productDetails?.images}
                        thumbImages={productDetails?.thumb_images}
                      />
                    )}
                  </div>
                </Flex>

                <Box
                  className='product-des'
                  flex={1}
                  w={{ base: '100%', lg: '45%' }}
                >
                  <ProductDescription fromHome={fromHome} />
                </Box>
                {isSmallerThan767 && (
                  <Box
                    className='right-component'
                    w={{ base: '100%', lg: '23%' }}
                    minW={'326px'}
                    pos={'sticky'}
                    top={'100px'}
                    maxH={`calc(100vh - 100px)`}
                    overflowY={'auto'}
                  >
                    <Suspense fallback={'Loading...'}>
                      <Logistics
                        isDataShowing={true}
                        fromHome={fromHome}
                        isCount={isCount}
                        setIsCount={(e) => setIsCount(e)}
                      />
                    </Suspense>
                  </Box>
                )}
              </Flex>
              {!fromHome && (
                <>
                  <Box>
                    <RecommendationFromSameSeller
                      title={
                        <Text
                          fontSize={isSmallerThan767 ? '18px' : '24px'}
                          fontWeight={700}
                          lineHeight={'30px'}
                          color={'#666'}
                        >
                          Store recommendations{' '}<br />
                          <span style={{ color: '#F41106' }}>{storeName}</span>
                        </Text>
                      }
                      onCartOpen={cartHandler}
                      storeName={storeName}
                    />
                  </Box>{' '}
                  <SimilarProducts />
                  <Box
                    className='features-and-ratings'
                    style={{ padding: '20px 10' }}
                  >
                    <Rating fromHome />
                  </Box>
                  <ReviewCard />
                  {product?.description && (
                    <Box mt={4} className='product-description-box'>
                      <Flex
                        justify={'space-between'}
                        align={'center'}
                        mb='20px'
                      >
                        <Text fontSize='lg' fontWeight='bold'>
                          Description:
                        </Text>
                      </Flex>

                      <Box
                        maxH={isExpanded ? 'none' : '1200px'}
                        overflow='hidden'
                        position='relative'
                        ref={contentRef}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: product?.description,
                          }}
                        />
                      </Box>
                      {showViewMore && (
                        <Box display='flex' justifyContent='center' mt={5}>
                          {loading ? (
                            <Spinner size='md' />
                          ) : (
                            <Text
                              cursor='pointer'
                              fontWeight={600}
                              textAlign='center'
                              textDecoration='underline'
                              mt={4}
                              onClick={handleToggle}
                              colorScheme='blue'
                              variant='outline'
                            >
                              {isExpanded ? 'Show Less' : 'Show More'}
                            </Text>
                          )}
                        </Box>
                      )}
                    </Box>
                  )}
                </>
              )}
            </Box>

            <Box
              className='right-component'
              w={{ base: '100%', lg: '23%' }}
              minW={'326px'}
              pos={'sticky'}
              top={'100px'}
              maxH={`calc(100vh - 100px)`}
              overflowY={'auto'}
            >
              <Suspense fallback={'Loading...'}>
                <Logistics
                  isDataShowing={false}
                  fromHome={fromHome}
                  isCount={isCount}
                  setIsCount={(e) => setIsCount(e)}
                />
              </Suspense>

              <RightComponent
                onClose={onClose}
                isCount={isCount}
                fromCart={fromCart}
              />
            </Box>

            {/* </Flex> */}
          </Flex>
        </div>

        <Modal
          isOpen={isOpenReportProduct}
          onClose={onCloseReportProduct}
          title='Report Product'
        >
          <Text>Please tell us about the issue</Text>
          <Select
            onChange={(e) => {
              if (e.target.value === '-1') {
                setReason('');
                return;
              }
              setReason(e.target.value);
            }}
          >
            <option value='-1'>Select</option>
            {reportCategores?.map((category) => (
              <option key={category.id} value={category?.name}>
                {category?.name}
              </option>
            ))}
          </Select>
          <Flex justify={'flex-end'} align={'center'} gap={'20px'} mt='20px'>
            <Button variant='secondary' onClick={onCloseReportProduct}>
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                const payload = {
                  user_id: userData.id,
                  product_id: product.id,
                  category: reason,
                  other_data: '',
                };
                dispatch(reportProductHandler(payload)).then((res) => {
                  if (res.status === 200) {
                    toast({
                      title: 'Product reported successfully',
                      status: 'success',
                    });
                    onCloseReportProduct();
                  } else {
                    toast({
                      title: 'Product report failed',
                      status: 'error',
                    });
                  }
                });
              }}
            >
              Submit
            </Button>
          </Flex>
        </Modal>
      </div>
    </>
  );
};

export default ProductDetail;
