import { Box, Button, Flex, FormControl, FormLabel, IconButton, Image, Select, Text, useDisclosure } from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import GlobeComponentIcon from '../../components/icons/globeIcon';
import SettingIcon from '../../components/icons/settingIcon';
import SettingIconRed from '../../assets/img/icons/settingIconRed.svg'
import { useSelector, useDispatch } from 'react-redux';
import { setCountry, setCurrency, setOnLoginOpen, setSelectedCountry } from '../../redux/homeSlice';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { CiBellOn, CiGlobe } from 'react-icons/ci';
import Dropdown from '../dropdown';
import { currencyList } from '../../utils/constants';
import { useEffect } from 'react';
import NotificationIcon from '../notificationIcon';

const AccountHeader = () => {
  const {
    isOpen: isDropdownOpen,
    onOpen: onDropdownOpen,
    onClose: onDropdownClose,
  } = useDisclosure();
  const {
    currency,
    country,
    countries,
  } = useSelector((state) => state.home);

  const { userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isSettingsPage = location.pathname === '/settings';
  const [isGlobIconHovered, setIsGlobIconHovered] = useState(false);
  const [currencies, setCurrencies] = useState([]);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        includedLanguages: 'en,fr,sw', // include this for selected languages
        // layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
      },
      'google_translate_element'
    );
  };

  const addScript = useCallback(() => {
    var addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  useEffect(() => {
    const country = localStorage.getItem('country');
    if (isDropdownOpen || country) {
      addScript();
    }
  }, [addScript, isDropdownOpen]);

  useEffect(() => {
    const _country = localStorage.getItem('country') || country;
    const _currency = localStorage.getItem('currency') || currency;

    dispatch(setCountry(_country));
    if (_country) {
      dispatch(setCurrency(_currency));
      setCurrencies(
        currencyList.filter(
          (c) =>
            c.code === 'USD' || c.code.includes(_country)
          // c.code === 'EUR' || removed Euro
        )
      );

      dispatch(setSelectedCountry(countries.find((c) => c.iso === _country)));

      // document.cookie = `googtrans=/en/${country}`;
    }
  }, [countries, country, currency, dispatch]);

  return (
    <Flex padding={'13px 20px'} align={'center'} bg='#fff' justifyContent='space-between'>
      <Flex align="center">
        {isSettingsPage ? (
          <>
            <IconButton
              icon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
              aria-label="Back"
              variant="ghost"
              fontSize='20px'
            // pb='7px'
            />
            <Text fontSize={'20px'} fontWeight={600} ml='5px'>
              Settings
            </Text>
          </>
        ) : (
          <Text fontSize={'18px'} fontWeight={700}>
            {userData?.name ? (
              <b>{userData.name}</b>
            ) : (
              <Box as="span" cursor="pointer" onClick={() => dispatch(setOnLoginOpen(true))}>
                <b>Sign in / Register</b>
              </Box>
            )}
          </Text>
        )}
      </Flex>

      <Flex gap={'18px'}>
        <Box >
          <span
            onClick={(e) => {
              e.stopPropagation();
              if (isDropdownOpen) {
                onDropdownClose();
              } else {
                onDropdownOpen();
              }
            }}
            onMouseEnter={() => setIsGlobIconHovered(true)}
            onMouseLeave={() => setIsGlobIconHovered(false)}
          >
            <CiGlobe
              size={24}
              color={
                isDropdownOpen || isGlobIconHovered ? 'red' : '#808080'
              }
            />
          </span>

          <Dropdown isOpen={isDropdownOpen} onClose={onDropdownClose} ml='-190px !important'>
            <Flex
              onClick={(e) => {
                e.stopPropagation();
              }}
              minW={'255px'}
              flexDir={'column'}
              gap={'10px'}
            >
              <FormControl>
                <FormLabel
                  fontSize={'15px'}
                  fontWeight={600}
                  lineHeight={'19px'}
                >
                  Language
                </FormLabel>
                <Box id='google_translate_element'></Box>
              </FormControl>
              <FormControl>
                <FormLabel
                  fontSize={'15px'}
                  fontWeight={600}
                  lineHeight={'19px'}
                >
                  Country
                </FormLabel>
                <Select
                  placeholder='Select option'
                  onChange={(e) => {
                    dispatch(setCountry(e.target.value));
                    localStorage.setItem('country', e.target.value);

                    dispatch(
                      setCurrency(
                        currencyList.find((c) =>
                          c.code.includes(e.target.value)
                        ).code
                      )
                    );
                  }}
                  value={'TZ'} // Set default value for Tanzania
                  isDisabled={true} // Disable the dropdown
                >
                  {countries.map((country) => (
                    <option key={country.iso} value={country.iso}>
                      {country.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel
                  fontSize={'15px'}
                  fontWeight={600}
                  lineHeight={'19px'}
                >
                  Currency
                </FormLabel>
                <Select
                  placeholder='Select option'
                  value={currency}
                  onChange={(e) => {
                    localStorage.setItem('currency', e.target.value);
                    dispatch(setCurrency(e.target.value));
                  }}
                >
                  {currencies.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Button
                mt={'10px'}
                variant={'primary'}
                onClick={() => {
                  localStorage.setItem('country', country);
                  localStorage.setItem('currency', currency);

                  window.location.reload();
                }}
              >
                Save
              </Button>
            </Flex>
          </Dropdown>
        </Box>
        <Box w="24px" h="24px" onClick={() => navigate('/settings')}>
          {isSettingsPage ? (
            <Image src={SettingIconRed} alt="Settings Icon Red" width="100%" height="100%" />
          ) : (
            <SettingIcon width="100%" height="100%" />
          )}
        </Box>
        <Box >
          <NotificationIcon />
        </Box>
      </Flex>
    </Flex>
  );
};

export default AccountHeader;
