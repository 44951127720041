import { createSlice } from '@reduxjs/toolkit';
import {
  getAllCategories,
  getCheckCart,
  getCountryList,
  getHomeData,
  getNowBought,
  getProducts,
  newsletterSubscribe,
  newsletterUnsubscribe,
  qandASubmit,
  saveSearch,
  submitNewSuggestion,
} from '../services/home-service';
import { act } from 'react';
import { currency } from '../utils/constants';

const initialState = {
  isLoading: false,
  isHomeLoading: false,
  homeData: {
    banners: [],
    category: [],
    best_deal_products: [],
    hot_deals: [],
    products: [],
  },
  products: [],
  store:[],
  isLogin: true,
  onLoginOpen: false,
  redirectionPath: null,
  allCategories: [],
  subCategories: [],
  subSubCategories: [],
  productList: [],
  subCategoryOpen: false,
  country: 'TZ', // TZ
  currency: 'TZS', // TZS
  selectedCountry: null,
  countries: [],
  allProducts: [],
  hasMore: true,
  totalResults: 0,
  page: 1,
  isAccountPage: false,
  isCategoryOpen: false,
  isProductLoading: false,
  nowBought: null,
  nowBoughtTimer: 25,
  isNowBoughtVisible: false,
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsHomeLoading(state, action) {
      state.isHomeLoading = action.payload;
    },
    setIsProductLoading(state, action) {
      state.isProductLoading = action.payload;
    },
    setHomeData(state, action) {
      state.homeData = action.payload;
    },
    setProducts(state, action) {
      state.products = [...state.products, ...action.payload];
    },
    setStore(state, action) {
      state.store = action.payload;
    },
    setProductCard(state, action) {
      state.products = [...action.payload];
    },
    setIsLogin(state, action) {
      state.isLogin = action.payload;
    },
    setOnLoginOpen(state, action) {
      state.onLoginOpen = action.payload;
    },
    setRedirectionPath(state, action) {
      state.redirectionPath = action.payload;
    },
    setAllCategories(state, action) {
      state.allCategories = action.payload;
    },
    setSubCategories(state, action) {
      state.subCategories = action.payload;
    },
    setSubSubCategories(state, action) {
      state.subSubCategories = action.payload;
    },
    setProductList(state, action) {
      state.productList = action.payload;
    },
    setSubCategoryOpen(state, action) {
      state.subCategoryOpen = action.payload;
    },
    setCountry(state, action) {
      state.country = action.payload;
    },
    setCurrency(state, action) {
      state.currency = action.payload;
    },
    setProductClean(state) {
      state.products = [];
    },
    setSelectedCountry(state, action) {
      state.selectedCountry = action.payload;
    },
    setCountries(state, action) {
      state.countries = action.payload;
    },
    setAllProducts(state, action) {
      state.allProducts = action.payload;
    },
    setHasMore(state, action) {
      state.hasMore = action.payload;
    },
    setTotalResults(state, action) {
      state.totalResults = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setNowBought(state, action) {
      state.nowBought = action.payload;
      state.isNowBoughtVisible = true;
      state.nowBoughtTimer = 25;
    },
    decrementTimer: (state) => {
      if (state.nowBoughtTimer > 0) state.nowBoughtTimer -= 1;
    },
    hideNowBought: (state) => {
      state.isNowBoughtVisible = false;
    },
    setIsAccountPage(state, action) {
      state.isAccountPage = action.payload;
    },
    setIsCategoryOpen(state, action) {
      state.isCategoryOpen = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setHomeData,
  setProducts,
  setStore,
  setProductCard,
  setIsLogin,
  setOnLoginOpen,
  setRedirectionPath,
  setAllCategories,
  setSubCategories,
  setSubSubCategories,
  setProductList,
  setSubCategoryOpen,
  setCountry,
  setCurrency,
  setProductClean,
  setSelectedCountry,
  setCountries,
  setAllProducts,
  setHasMore,
  setTotalResults,
  setPage,
  setNowBought,
  decrementTimer,
  hideNowBought,
  setIsAccountPage,
  setIsCategoryOpen,
  setIsProductLoading,
  setIsHomeLoading,
} = homeSlice.actions;

export default homeSlice.reducer;

export const getHomeDataHandler = () => async (dispatch, getState) => {
  try {
    dispatch(setIsHomeLoading(true));
    const country = getState()?.home?.country;
    const currency = getState()?.home?.currency;
    const userData = getState()?.user?.userData;
    const guestInfo = getState()?.guest?.guestInfo;
    const user_id = userData?.id || guestInfo?.id;

    if (country && currency && user_id) {
      const response = await getHomeData(country, currency, user_id);
      if (response.status === 200) {
        dispatch(setHomeData(response.data.data));
      }
    }
    setTimeout(() => {
      dispatch(setIsHomeLoading(false));
    }, 2000);
  } catch (error) {
    dispatch(setIsHomeLoading(false));
    return error;
  }
};
export const fetchAllCategories = () => async (dispatch, getState) => {
  try {
    const country = getState()?.home?.country;
    const currency = getState()?.home?.currency;
    if (country && currency) {
      let response = await getAllCategories(country, currency);
      if (response?.status === 200) {
        dispatch(setAllCategories(response.data.data));
        response.data.data.forEach((el, index) => {
          if (index === 0) {
            el.isActive = true;
          }
        });
        //  response.data.data[0]?.isActive = true;
        dispatch(setSubCategories(response.data.data[0]?.subcat_data));
        dispatch(
          setSubSubCategories(
            response.data.data[0]?.subcat_data[0]?.subcat_data
          )
        );
      }
    }
  } catch (error) {
    return error;
  }
};
export const getProductsHandler =
  (params, more) => async (dispatch, getState) => {
    try {
      dispatch(setIsProductLoading(true));
      const allProducts = getState().home.allProducts;

      const country = getState().home.country;
      const currency = getState().home.currency;
      const userData = getState().user.userData;
      const guestInfo = getState().guest.guestInfo;
      const user_id = userData?.id || guestInfo?.id;

      const response = await getProducts(params, country, currency, user_id);

      if (response?.response?.status !== 200) {
        dispatch(setTotalResults(0));
      }

      if (response.status === 200) {
        if (!more) {
          dispatch(setAllProducts(response.data.data));
        } else {
          dispatch(setAllProducts([...allProducts, ...response.data.data]));
        }
        dispatch(setTotalResults(response.data.total_count));
      }
      dispatch(setIsProductLoading(false));
      return response;
    } catch (error) {
      dispatch(setIsProductLoading(true));
      return error;
    }
  };

export const fetchCountryList = () => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true));
    const response = await getCountryList();
    if (response.status === 200) {
      dispatch(setCountries(response.data.data));
    }
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return error;
  }
};
export const fetchNowBought = () => async (dispatch, getState) => {
  try {
    const response = await getNowBought();
    if (response.status === 200) {
      dispatch(setNowBought(response.data.data));
    }
  } catch (error) {
    return error;
  }
};
export const getCheckCartFunction = () => async (dispatch, getState) => {
  const userData = getState().user.userData;
  const guestInfo = getState().guest.guestInfo;
  const user_id = userData?.id || guestInfo?.id;
  const currency = getState().home.currency;
  const country = getState().home.country;
  try {
    const response = await getCheckCart(user_id, currency, country );
    return response;
  } catch (error) {
    return error;
  }
};
export const qandASubmitHandler = (paylaod) => async (dispatch, getState) => {
  try {
    return await qandASubmit(paylaod);
  } catch (error) {
    return error;
  }
};
export const submitNewSuggestionHandler =
  (paylaod) => async (dispatch, getState) => {
    try {
      return await submitNewSuggestion(paylaod);
    } catch (error) {
      return error;
    }
  };
export const newsletterSubscribeHandler =
  (paylaod) => async (dispatch, getState) => {
    try {
      return await newsletterSubscribe(paylaod);
    } catch (error) {
      return error;
    }
  };

export const newsletterUnsubscribeHandler =
  (paylaod) => async (dispatch, getState) => {
    try {
      return await newsletterUnsubscribe(paylaod);
    } catch (error) {
      return error;
    }
  };

export const saveSearchHandler = (keyWord) => async (dispatch, getState) => {
  try {
    return await saveSearch(keyWord);
  } catch (error) {
    return error;
  }
};
