import { Badge, Flex, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCartList } from '../../redux/cartSlice';

const OrderDetails = () => {
  const dispatch = useDispatch();

  const { checkoutData } = useSelector((state) => state.order);

  const isDiscountMoreThanTotalAamount =
    checkoutData?.total_amount?.replaceAll(',', '') < 0;

  return (
    <Flex
      className='checkout-border'
      p={4}
      backgroundColor='white'
      flexDir={'column'}
      gap={'.70rem'}
      height={'fit-content'}
      flex={1}
    >
      {/* <Flex justify={'space-between'} align={'center'}>
        <Badge
          style={{
            backgroundColor: '#feebeb',
            padding: '5px',
            borderRadius: '10px',
          }}
        >
          <Text color='red'>Coupon-20</Text>
        </Badge>
        <Text color={'#26bf94'}>Coupon applied</Text>
      </Flex>
      <hr /> */}
      <Flex justify={'space-between'} align={'center'}>
        <Text className='checkout-title-text'>Sub Total</Text>
        <Text fontWeight='bold'>{checkoutData?.sub_total_amount}</Text>
      </Flex>
      <Flex justify={'space-between'} align={'center'}>
        <Text className='checkout-title-text'>Discount</Text>
        <Text color={'#26bf94'}>{checkoutData?.discount || '-'}</Text>
      </Flex>
      <Flex justify={'space-between'} align={'center'}>
        <Text className='checkout-title-text'>Delivery Charges</Text>
        <Text color={''}> {checkoutData?.shipment_amount || '-'}</Text>
      </Flex>
      {/* <Flex justify={'space-between'} align={'center'}>
        <Text className='checkout-title-text'>Service Tax (18%)</Text>
        <Text>- 169</Text>
      </Flex> */}
      <hr />
      <Flex justify={'space-between'} align={'center'}>
        <Text fontWeight='bold'>Total :</Text>
        <Text fontWeight='bold'>
          {isDiscountMoreThanTotalAamount ? 0 : checkoutData?.total_amount}
        </Text>
      </Flex>
    </Flex>
  );
};

export default OrderDetails;
