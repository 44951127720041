import React, { useEffect, useState } from 'react';
import {
  Flex,
  Text,
  Box,
  Button,
  Image,
  Select,
  Textarea,
  Input,
  useToast,
  Heading,
  RadioGroup,
  Stack,
  Radio,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Spacer,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
  useMediaQuery,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrders, refundCategoryHandler, refundOrderHandler } from '../../redux/orderSlice';
import { FaImage, FaVideo } from 'react-icons/fa';
import InstructionsModal from './instructionsModal';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';

const ReturnOrder = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const { orders } = useSelector((state) => state.order);
  const [orderId, setOrderId] = useState('');
  const [reason, setReason] = useState('');
  const [problemDescription, setProblemDescription] = useState('');

  const [order, setOrder] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [refundCategory, setRefundCategory] = useState(null);
  const [hoveredCategory, setHoveredCategory] = useState(null);

  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [refundMethod, setRefundMethod] = React.useState('Original payment');
  const [email, setEmail] = React.useState('');
  const [mobile, setMobile] = useState('');
  const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const [activeCategory, setActiveCategory] = useState(null);

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  useEffect(() => {
    if (orders.length > 0) {
      const foundOrder = orders.find((order) => order.id === Number(id));
      setOrder(foundOrder);
      setOrderId(foundOrder?.id);
    }
  }, [orders, id]);

  useEffect(() => {
    refundCategoryOptions();
  }, []);

  const refundCategoryOptions = () => {
    dispatch(refundCategoryHandler()).then((res) => {
      setRefundCategory(res.data.data);
    });
  };

  useEffect(() => {
    dispatch(fetchOrders()).then((res) => {
      if (res.payload && res.payload.length > 0) {
        setSelectedOrder(res.payload[0]);
      }
    });
  }, [dispatch]);

  const moveToFirstStep = () => {
    setCurrentStep(1);
  };

  const moveToSecondStep = () => {
    setCurrentStep(2);
  };

  const handleImageUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files);
    const validImages = uploadedFiles.filter((file) => file.type.startsWith('image/'));
    if (validImages.length > 0) {
      setImages([...images, ...validImages]);
      toast({
        title: 'Image uploaded successfully!',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Only image files are allowed.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const handleVideoUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files);
    const validVideos = uploadedFiles.filter((file) => file.type.startsWith('video/') && file.size <= 2 * 1024 * 1024);
    if (validVideos.length > 0) {
      setVideos([...videos, ...validVideos]);
      toast({
        title: 'Video uploaded successfully!',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    }

    const oversizedFiles = uploadedFiles.filter((file) => file.size > 2 * 1024 * 1024);
    if (oversizedFiles.length > 0) {
      toast({
        title: 'Video size exceeds the 2MB limit.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const submitRefundOrder = async () => {
    const formData = new FormData();
    formData.append('order_id', orderId);
    formData.append('reason', reason);
    formData.append('return_method', refundMethod);
    formData.append('email', email);
    formData.append('mobile_number', mobile);
    images.forEach((image, index) => {
      formData.append(`return_image[${index}]`, image);
    });
    videos.forEach((video, index) => {
      formData.append(`return_video[${index}]`, video);
    });

    try {
      const res = await dispatch(refundOrderHandler(formData));
      if (res.status === 200) {
        toast({
          title: 'Refund request submitted successfully',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        navigate('/my-orders');
      } else {
        toast({
          title: res?.response?.data?.message ?? 'Something went wrong',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: error.message ?? 'An error occurred',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const formatRefundCategories = () => {
    return refundCategory?.map((category) => ({
      label: category.name,
      value: category.id,
      options: category.subcategory ? category.subcategory.map((sub) => ({
        label: sub.name,
        value: sub.id
      })) : [],
    }));
  };

  const toggleCategory = (categoryId) => {
    setRefundCategory((prev) => (prev === categoryId ? null : categoryId));
  };

  return (
    <Box mb="30px" p={{ base: "10px", md: "20px" }} maxH='100vh'>
      <Flex direction={{ base: "column", md: "row" }} align="center" mb={{ base: '0', md: '20px' }}>
        <Text fontSize={{ base: "lg", md: "xl" }} fontWeight={600} mb={{ base: "10px", md: "0" }}>
          Request a Return/Refund
        </Text>
        <Spacer display={{ base: "none", md: "block" }} />
        <InstructionsModal />
      </Flex>
      <br />
      <Flex align="center" justify="space-between" w="100%" mb={{ base: '0', md: '20px' }} px={{ base: '10px', md: '200px' }}>
        <Flex direction="column" align="center">
          <Box
            borderRadius="50%"
            bg={currentStep >= 1 ? 'red.500' : 'gray.300'}
            color="white"
            width="40px"
            height="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            1
          </Box>
          <Text mt="10px">Select article</Text>
        </Flex>

        <Box
          flex="1"
          height="2px"
          mb='35px'
          bg={currentStep >= 2 ? 'red.500' : 'gray.300'}
        />
        <Flex direction="column" align="center">
          <Box
            borderRadius="50%"
            bg={currentStep >= 2 ? 'red.500' : 'gray.300'}
            color={currentStep >= 2 ? 'white' : 'black'}
            width="40px"
            height="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            2
          </Box>
          <Text mt="10px">Select method</Text>
        </Flex>
      </Flex>
      <br />
      {currentStep === 1 && (
        <Box width={{ base: "100%", md: "50%" }}>
          <Flex align="center" mb="20px" gap="20px">
            <Box>
              <Image
                src={order?.product_image}
                alt="Product Image"
                boxSize={{ base: "80px", md: "100px" }}
                width="130px"
                height="90px"
                minW="100px"
                minH="70px"
                border="gray 1px solid"
                borderRadius="10px"
              />
            </Box>
            <Box>
              <Text fontWeight="600">{order?.product_name}</Text>
              <Text fontSize="sm">{order?.productDescription}</Text>
            </Box>
          </Flex>

          <Box className="dropdown" position="relative" mb="20px" style={{ width: '100%' }}>
            <Menu closeOnSelect={false}>
              {({ onClose }) => (
                <>
                  <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    style={{
                      minWidth: '100%',
                      backgroundColor: 'transparent',
                      color: 'black',
                      border: '1px solid gray',
                      textAlign: 'start',
                    }}
                  >
                    {reason || 'Please select reason'}
                  </MenuButton>

                  <MenuList>
                    {formatRefundCategories()?.map((category) => (
                      <Box
                        key={category.value}
                        position="relative"
                        onMouseEnter={() => {
                          if (!isMobile && category.options.length > 0) {
                            setHoveredCategory(category.value);
                          }
                        }}
                        onMouseLeave={() => {
                          if (!isMobile) setHoveredCategory(null);
                        }}
                      >
                        {/* MenuItem for Categories */}
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            if (isMobile) {
                              if (category.options.length > 0) {
                                setActiveCategory((prev) =>
                                  prev === category.value ? null : category.value
                                );
                              } else {
                                setReason(category.label);
                                setActiveCategory(null);
                                setHoveredCategory(null);
                                onClose();
                              }
                            } else {
                              if (category.options.length === 0) {
                                setReason(category.label);
                                setActiveCategory(null);
                                setHoveredCategory(null);
                                onClose();
                              }
                            }
                          }}
                          _hover={{
                            bgGradient: 'linear(to-r, red.500, red.300)',
                            color: 'white',
                          }}
                        >
                          <HStack justify="space-between" width="100%">
                            <Box>{category.label}</Box>
                            {category.options.length > 0 && <ChevronRightIcon boxSize={4} />}
                          </HStack>
                        </MenuItem>

                        {/* Subcategories */}
                        {(isMobile
                          ? activeCategory === category.value
                          : hoveredCategory === category.value) &&
                          category.options.length > 0 && (
                            <Box
                              position={isMobile ? 'static' : 'absolute'}
                              top="0"
                              left="100%"
                              zIndex="10"
                              bg="white"
                              border="1px solid gray"
                              borderRadius="md"
                              boxShadow="md"
                              p={2}
                              minW="300px"
                            >
                              {category.options.map((sub) => (
                                <Box
                                  key={sub.value}
                                  p={2}
                                  _hover={{
                                    bgGradient: 'linear(to-r, red.500, red.300)',
                                    color: 'white',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setReason(sub.label);
                                    setActiveCategory(null);
                                    setHoveredCategory(null);
                                    onClose(); // Close both subcategory and category popups
                                  }}
                                >
                                  {sub.label}
                                </Box>
                              ))}
                            </Box>
                          )}
                      </Box>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>

          </Box>

          <Text mb="10px" fontWeight="600">Upload a picture/video</Text>
          <Box position="relative" mb="10px">
            <Textarea
              placeholder="Please describe your problem."
              value={problemDescription}
              onChange={(e) => setProblemDescription(e.target.value)}
              mb="10px"
              minHeight="100px"
            />
            <Flex position="absolute" bottom="15px" left="10px" gap="10px">
              <IconButton
                icon={<FaImage />}
                size="md"
                variant="secondary"
                aria-label="Upload Image"
                onClick={() => document.getElementById('image-upload').click()}
              />
              <IconButton
                icon={<FaVideo />}
                size="md"
                variant="secondary"
                aria-label="Upload Video"
                onClick={() => document.getElementById('video-upload').click()}
              />
            </Flex>
          </Box>

          <Input type="file" id="image-upload" accept="image/*" display="none" onChange={handleImageUpload} />
          <Input type="file" id="video-upload" accept="video/*" display="none" onChange={handleVideoUpload} />
        </Box>
      )}

      {currentStep === 2 && (
        <>
          <Box p={6} borderRadius="md" boxShadow="md">
            <Heading as="h4" size="lg" mb={4}>
              Select a refund method
            </Heading>

            <RadioGroup onChange={setRefundMethod} value={refundMethod}>
              <Stack spacing={4}>
                <HStack align="start">
                  <Radio value="Original payment" mt="6px" />
                  <Box>
                    <Text fontWeight="bold" fontSize="lg">Original payment method</Text>
                    <Text fontSize="sm" fontWeight="bold">{order?.payment_method == "ONLINE" ? "Ezipay / Paypal" : order?.payment_method}</Text>
                    <Text fontSize="sm" color="gray">
                      Once the request is approved, refunds are issued within 3-14 business days (some payment methods may take up to 20 business days, depending on the bank or financial institution)
                    </Text>
                    <br />
                    <Input
                      type="number"
                      maxLength={10}
                      style={{borderRadius:'10px', marginLeft:'-10px'}}
                      value={mobile}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, ''); 
                        if (value.length <= 10) {
                          setMobile(value);
                        }
                      }}
                      placeholder="Enter your mobile no."
                    />
                  </Box>
                </HStack>
              </Stack>
            </RadioGroup>
          </Box>

          <Box p={6} borderRadius="md" boxShadow="md" mt="20px">
            <Text fontSize="lg" fontWeight="bold">Estimated refund amount: {order?.currency} {order?.price}</Text>
          </Box>

          <Box p={6} borderRadius="md" boxShadow="md" mt="20px">
            <FormControl isRequired>
              <FormLabel>Confirm your email address</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
              <Text mt="5px" fontSize="sm" color="gray">
                Confirm your email to receive return labels, return updates, and refund notifications.
              </Text>
            </FormControl>
          </Box>
        </>
      )}

      <br />
      <br />
      {/* Next Step Button */}
      <Flex justifyContent="center"
        mt={{ base: '-30px', md: '0px' }}
        pb={{ base: '60px', md: '0px' }}>
        {currentStep === 2 &&
          <Button
            mr='10px'
            variant='secondary'
            width="100%" maxW='300px' borderRadius='20px'
            onClick={moveToFirstStep}
          >
            Back
          </Button>}
        <Button
          width="100%" maxW='300px' borderRadius='20px'
          onClick={currentStep === 1 ? moveToSecondStep : submitRefundOrder}
          disabled={
            (currentStep === 1 && (!reason || images.length === 0))
            ||
            (currentStep === 2 && !isValidEmail)
          }
        >
          {currentStep === 1 ? 'Next Step' : 'Submit'}
        </Button>
      </Flex>
    </Box>
  );
};

export default ReturnOrder;
