import {
  Box,
  Container,
  Flex,
  Spinner,
  Text,
  useMediaQuery,
  useOutsideClick,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import SubCategories from '../sub-categories';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAllCategories,
  setIsCategoryOpen,
  setSubCategories,
  setSubSubCategories,
} from '../../redux/homeSlice';
import styles from './header.module.scss';

const Categories = () => {
  const dispatch = useDispatch();
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const [isSmallerThan1199] = useMediaQuery('(max-width: 1199px)');
  const { allCategories, isCategoryOpen } = useSelector((state) => state.home);
  const [catTitile, setCatTitle] = useState('');
  const ref = useRef();
  useOutsideClick({
    ref: ref,
    handler: () => {
      dispatch(setIsCategoryOpen(false));
    },
  });

  useEffect(() => {
    if (allCategories.length > 0) {
      const firstCategory = allCategories[0];
      setCatTitle(firstCategory.name);
      dispatch(setSubCategories(firstCategory.subcat_data));

      const updatedCategories = allCategories.map((item, index) => ({
        ...item,
        isActive: index === 0,
      }));

      dispatch(setAllCategories(updatedCategories));
      dispatch(setSubSubCategories([]));
    }
  }, []);

  useEffect(() => {
    const rootElement = document.getElementById('root');

    if (isCategoryOpen) {
      rootElement.style.overflow = 'hidden';
    } else {
      rootElement.style.overflow = '';
    }

    return () => {
      rootElement.style.overflow = '';
    };
  }, [isCategoryOpen]);

  return (
    <Box className={styles.all_category} ref={ref}>
      <Container
        maxW='100%'
        padding={isSmallerThan1199 ? '10px !important' : '0 120px'}
        // padding={isSmallerThan1199 ? '0 !important' : '0 120px'}
        // mt={{base:'14px', md:'0'}}
      >
        {allCategories.length > 0 && (
          <Flex>
            <Box
              minW={isSmallerThan1199 ? '130px' : 'auto'}
              width={'20%'}
              // borderRight={'1px solid #E2E2E2'}
              pr={isSmallerThan1199 ? '0' : '24px'}
              mb={{ base: '0', md: '-50px' }}
              className={styles.categories_parent}
            >
              <Text
                fontSize={isSmallerThan1199 ? '13px' : '16px'}
                fontWeight={600}
                lineHeight={'26px'}
                mb='12px'
                ml={isSmallerThan1199 ? '3px' : '10px'}
                display={isSmallerThan767 ? 'none' : ''}
                className={styles.categories_title}
              >
                CATEGORIES
              </Text>
              <Flex
                gap={'5px'}
                maxHeight={isSmallerThan1199 ? 'calc(100vh - 128px)' : '499px'}
                minH={isSmallerThan767 ? '100%' : ''}
                overflowY={'auto'}
                direction='column'
                mb={{ base: '50px', md: '' }}
                bg={isSmallerThan1199 ? '#F0F0F0' : '#fff'}
                className={styles.categories_child + ' ' + 'anvHide_overFlow'}
              >
                {allCategories.length === 0 ? (
                  <Flex align={'center'} justify={'center'} height={'100%'}>
                    <Spinner />
                  </Flex>
                ) : (
                  allCategories.map((item, index) => (
                    <Text
                      className={styles.cat_menu_item}
                      cursor={'pointer'}
                      onMouseEnter={() => {
                        setCatTitle(item.name);
                        dispatch(setSubCategories(item.subcat_data));
                        const temp = allCategories.map(
                          (innerItem, innerIndex) => ({
                            ...innerItem,
                            isActive: innerIndex === index,
                          })
                        );

                        dispatch(setAllCategories(temp));
                        dispatch(setSubSubCategories([]));
                      }}
                      onMouseLeave={() => {
                        const temp = allCategories.map(
                          (innerItem, innerIndex) => ({
                            ...innerItem,
                            isActive: innerIndex === index,
                          })
                        );

                        dispatch(setAllCategories(temp));
                      }}
                      pl={isSmallerThan1199 ? '5px' : '10px'}
                      py={'5px'}
                      backgroundColor={
                        item.isActive
                          ? isSmallerThan1199
                            ? '#fff'
                            : '#FFEFEE'
                          : 'transparent'
                      }
                      fontSize={isSmallerThan1199 ? '12px' : '15px'}
                      paddingBottom={
                        isSmallerThan767 && index === allCategories.length - 1
                          ? '45px'
                          : '0'
                      }
                    >
                      {item.name}
                    </Text>
                  ))
                )}
              </Flex>
            </Box>
            <Box flex={1}>
              <SubCategories title={catTitile} setCatTitle={setCatTitle} />
            </Box>
          </Flex>
        )}
      </Container>
      <Box
        onClick={() => dispatch(setIsCategoryOpen(false))}
        className={styles.overlay}
      ></Box>
    </Box>
  );
};

export default Categories;
