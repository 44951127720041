import React from "react";
import {
  Box,
  Avatar,
  Text,
  Stack,
  Flex,
  Circle,
  Icon,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import Profile from "../../assets/img/product/profileImage.jpg";
import { FaEnvelope, FaMoneyCheck, FaShoppingBag, FaUserCheck } from "react-icons/fa";

const NotificationCard = ({ notification }) => {
  const getNotificationIcon = (type) => {
    const typeAsNumber = Number(type);
    switch (typeAsNumber) {
      case 1:
        return <Icon as={FaShoppingBag} boxSize="8" color="blue.500" />;
      case 2:
        return <Icon as={FaMoneyCheck} boxSize="8" color="green.500" />;
      case 3:
        return <Icon as={FaUserCheck} boxSize="8" color="purple.500" />;
      case 4:
        return <Icon as={FaEnvelope} boxSize="8" color="orange.500" />;
      default:
        return <Icon as={FaUserCheck} boxSize="8" color="gray.500" />;
    }
  };

  const date = new Date(notification?.created_at).toLocaleDateString();
  const time = new Date(notification?.created_at).toLocaleTimeString();

  return (
    <Box
      bg="white"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={4}
      mb={4}
      boxShadow="md"
      width="100%"
      minH="100px"
      position="relative"
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        width: "4px",
        bg: "#F41106",
        borderRadius: "full",
        boxShadow: "lg",
      }}
    >
      <Flex alignItems="start" mb={2} flexDir={{ base: "column", md: "row" }} >
        <Box position="relative" ml={5} mr={5}>
          {getNotificationIcon(notification?.type)}
          {/* <Avatar
            size="md"
            name={notification.name}
            src={Profile}
            mr={3}
          /> */}
        </Box>
        <Stack direction="row" spacing={0} flex="1">
          <Flex flexDir='column'>
            <Text color="#696969">{notification?.message}</Text>

            <Text fontSize="sm" color="gray.500">
              {time}
            </Text>

          </Flex>
        </Stack>
      </Flex>
      <Flex justify="flex-end" align="center">
        {/* <Text fontSize="sm" color="gray.500">
          {time}
        </Text> */}
        <Text
          fontSize="sm"
          color="gray.500"
          bg="gray.100"
          px={2}
          py={1}
          borderRadius="md"
        >
          {date}
        </Text>
      </Flex>
    </Box>
    );
};

export default NotificationCard;
