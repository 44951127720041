import React, { useEffect, useRef, useState } from 'react';
import { Box, Text, Button, Textarea, Image, Input, useToast, VStack, FormControl, FormLabel, FormHelperText, Flex, IconButton, Switch, Spacer } from '@chakra-ui/react';
import { FaImage, FaVideo } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RiCamera2Fill, RiCloseCircleFill, RiStarFill, RiStarHalfFill, RiStarLine } from 'react-icons/ri';
import { submitReview } from '../../services/order-service';

const SubmitReview = () => {
  const toast = useToast();
  const { id } = useParams();
  const navigate = useNavigate();
  const hiddenFileInput = useRef(null);
  const [reviewText, setReviewText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [images, setImages] = useState([]);
  const [order, setOrder] = useState(null);
  const [orderId, setOrderId] = useState('');
  const { orders } = useSelector((state) => state.order);
  const [hoverRating, setHoverRating] = useState(0);
  const [rating, setRating] = useState(0);
  const [isAnonymous, setIsAnonymous] = useState(0);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleToggleChange = (e) => {
    setIsAnonymous(e.target.checked ? 1 : 0);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('product_id', order?.product_id || '');
    formData.append('user_id', order?.user_id || '');
    formData.append('rating', rating);
    formData.append('review', reviewText);
    formData.append('is_anonymous', isAnonymous);
    images.forEach((image, index) => {
      formData.append(`images[${index}]`, image);
    });

    try {
      const response = await submitReview(formData);
      if (response.status === 200) {
        toast({
          title: 'Review Submitted',
          description: 'Your review has been successfully submitted.',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        setReviewText('');
        setRating(0);
        setImages([]);
        setIsAnonymous(0);
        navigate('/my-orders');
      } else {
        toast({
          title: 'Error',
          description: 'Failed to submit review. Please try again later.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Something went wrong. Please try again later.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (orders.length > 0) {
      const foundOrder = orders.find((order) => order.id === Number(id));
      setOrder(foundOrder);
      setOrderId(foundOrder?.id);
    }
  }, [orders, id]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const filesFormats = [
      '.doc',
      '.docx',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'image/png',
      'image/gif',
      'image/jpeg',
      'video/mp4',
    ];

    const isFileValid = filesFormats.includes(file.type);
    if (!isFileValid) {
      return toast({
        title: 'Invalid file format',
        status: 'error',
      });
    }

    if (file.size > 16 * 1024 * 1024) {
      toast({
        title: `File ${file.name} is larger than 16MB!`,
        status: 'error',
      });
      return;
    }
    setImages([...images, file]);
  };

  const calculateRating = (event, index) => {
    const rect = event.target.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const width = rect.width;

    const fraction = Math.ceil((clickX / width) * 4) / 4;
    setRating(index + fraction);
  };

  const calculateHoverRating = (event, index) => {
    const rect = event.target.getBoundingClientRect();
    const hoverX = event.clientX - rect.left;
    const width = rect.width;
    const fraction = Math.ceil((hoverX / width) * 4) / 4;
    setHoverRating(index + fraction);
  };

  const renderStars = () => {
    return Array(5)
      .fill(0)
      .map((_, index) => {
        const starValue = index + 1;
        return (
          <Box
            key={index}
            onMouseMove={(e) => calculateHoverRating(e, index)}
            onMouseLeave={() => setHoverRating(0)}
            onClick={(e) => calculateRating(e, index)}
            cursor="pointer"
            position="relative"
            fontSize="2xl"
            color={(hoverRating || rating) >= starValue - 0.75 ? 'gold' : 'gray.300'}

          >
            {(hoverRating || rating) >= starValue ? (
              <RiStarFill />
            ) : (hoverRating || rating) >= starValue - 0.25 ? (
              <RiStarHalfFill />
            ) : (
              <RiStarLine />
            )}
          </Box>
        );
      });
  };

  return (
    <Box mb="30px" p={{ base: "10px", md: "20px" }} maxH='100vh'>
      <Flex direction="column" align="start" mb={{ base: '0', md: '20px' }}>
        <Text fontSize={{ base: "lg", md: "xl" }} fontWeight={600} mb={{ base: "10px", md: "0" }}>
          Order Information
        </Text>
        <br />
        <Flex dir={{ base: 'column', md: 'row' }} gap={{ base: '5px', md: '25px' }}>
          <Text>
            Order No. : <strong color='red'>{order?.order_no}</strong>
          </Text>
          <Text>
            Order closed : <strong>{order?.delivery_date}</strong>
          </Text>
          <Text>
            Seller Id: <strong>{order?.seller_id}</strong>
          </Text>
        </Flex>
      </Flex>
      <br />
      <br />
      <Box width={{ base: "100%", md: "50%" }}>
        <Flex align="center" mb="20px" gap="20px">
          <Box>
            <Image
              src={order?.product_image}
              alt="Product Image"
              boxSize={{ base: "80px", md: "100px" }}
              width="130px"
              height="90px"
              minW="100px"
              minH="70px"
              border="gray 1px solid"
              borderRadius="10px"
            />
          </Box>
          <Box>
            <Text fontWeight="600">{order?.product_name}</Text>
            <Text fontSize="sm">{order?.productDescription}</Text>
          </Box>
        </Flex>
        <br />
        <Flex mb="10px" align="center">
          <Text fontWeight="600" mr="10px">
            Rate the product:
          </Text>
          {renderStars()}
        </Flex>
        <br />
        <Text mb="10px" fontWeight="600">Upload a picture/video</Text>
        <Box position="relative" mb="10px">
          <Textarea
            placeholder="Please describe your experience."
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
            mb="10px"
            minHeight="100px"
          />
          <Box>
            <Flex gap={'10px'}>
              {images?.map((image, index) => (
                <>
                  <Box
                    pos={'relative'}
                    height={'54px'}
                    width={'54px'}
                    key={index}
                  >
                    <Box
                      pos={'absolute'}
                      right={'-5px'}
                      top={'-5px'}
                      cursor={'pointer'}
                      onClick={() => {
                        const temp = images.filter((item, i) => i !== index);
                        setImages(temp);
                      }}
                    >
                      <RiCloseCircleFill />
                    </Box>
                    <Image
                      height={'54px'}
                      width={'54px'}
                      src={URL.createObjectURL(image)}
                    />
                  </Box>
                </>
              ))}
              <Flex
                height={'54px'}
                width={'54px'}
                align={'center'}
                justify={'center'}
                cursor={'pointer'}
                onClick={handleClick}
                border={'1px dashed black'}
              >
                <RiCamera2Fill />
              </Flex>
            </Flex>

            <input
              type='file'
              onChange={handleFileChange}
              ref={hiddenFileInput}
              style={{ display: 'none' }}
            />
            <Text fontSize={'12px'} mt='10px'>
              You can upload a maximum 5 files, individual image size should not
              exceed 1MB and supported file formats are PNG and JPG.{' '}
            </Text>
          </Box>
        </Box>
        <br />

        <Flex direction='row' p='20px 5px'>
        <Text>Post review anonymously</Text>
          <Spacer />
          <Switch
            colorScheme="red"
            isChecked={isAnonymous === 1}
            onChange={handleToggleChange}
          />
        </Flex>

        <Button
        mb={{base:'50px', md:'0px'}}
          colorScheme="blue"
          width="full"
          borderRadius='full'
          onClick={handleSubmit}
          isDisabled={!reviewText || rating === 0}
          isLoading={isSubmitting}
          loadingText="Submitting"
        >
          Submit Review
        </Button>
      </Box>
    </Box>
  );
};

export default SubmitReview;
