import {
  Badge,
  Box,
  Button,
  Container,
  Flex,
  IconButton,
  Image,
  Text,
  Tooltip,
  Table,
  Tbody,
  Td,
  Stack,
  Icon,
  Input,
  useToast,
  useDisclosure,
  Checkbox,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import NumberGroupInput from '../../../components/number-group-input';
import { RiBox3Line } from 'react-icons/ri';
import styles from './cart.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  applyCouponHandler,
  deleteCartHandler,
  fetchCartList,
  removeCoupon,
  setCartList,
  setCoupon,
  setSelectedItems,
  setUpdatedCartDetails,
  updateCartQuantityHandler,
} from '../../../redux/cartSlice';
import { useNavigate } from 'react-router-dom';
import {
  calculateDiscountPercentage,
  formatePrice,
} from '../../../utils/functions';
import { AiOutlineSafety } from 'react-icons/ai';
import { GiThreeLeaves } from 'react-icons/gi';
import { addToWishlistHandler } from '../../../redux/wishlistSlice';
import ShippingModal from '../../product-detail/logistics/ship-option';
import ProductDetail from '../../product-detail';
import DeleteCartModal from './delete-cart-modal';
import Modal from '../../../components/modal';
import {
  fetchShipmentList,
  getDataProductSummary,
  setProductId,
} from '../../../redux/productSlice';
import boxIcon from '../../../assets/img/icons/boxIcon.svg';
import heartIcon from '../../../assets/img/icons/heart.svg';
import dustbinIcon from '../../../assets/img/icons/dustbinIcon.svg';
import SummaryMobile from './summary-mobile';
import { usehashParam } from '../../../hooks/useHashParam';
import { setVoucherData } from '../../../redux/paymentSlice';

let count = 0;

const Cart = ({ fromHeader }) => {
  const {
    isOpen: isShippingOpen,
    onOpen: onShippingOpen,
    onClose: onShippingClose,
  } = useDisclosure();
  const {
    isOpen: isOpenCart,
    onOpen: onOpenCart,
    onClose: onCloseCart,
  } = useDisclosure();
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const { userData } = useSelector((state) => state.user);
  const { guestInfo } = useSelector((state) => state.guest);
  const {
    cartDetails,
    updatedCartDetails,
    coupon,
    cartList,
    expiredCartList,
    selectedItems,
  } = useSelector((state) => state.cart);
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');

  const [appliedCoupon, setAppliedCoupon] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { currency, country } = useSelector((state) => state.home);
  const [selectedItem, setSelectedItem] = useState(null);
  const [productData, setProductData] = useState(null);
  const [qty, setQty] = useState(null);
  const [isOpenSummary, setIsOpenSummary] = useState(false);
  const [placement, setPlacement] = useState('bottom');

  const [priceDetails, setPriceDetails] = useState(null);

  const [isCouponApplied, setIsCouponApplied] = useState(false);

  useEffect(() => {
    if (cartDetails?.data?.length === 0) {
      dispatch(fetchCartList(userData?.id || guestInfo.id));
    }
  }, [cartDetails?.data?.length, dispatch, guestInfo.id, userData?.id]);

  const handleQuantityChange = (item, quantity) => {
    setQty(quantity);
    dispatch(updateCartQuantityHandler(item, quantity)).then((res) => {
      if (res.status === 200) {
        getProductPricesByIds(userData?.id || guestInfo?.id);
      }
    });
  };

  const handleDeleteClick = (item) => {
    setSelectedItem(item);
    onDeleteModalOpen();
  };

  const confirmDelete = () => {
    if (selectedItem) {
      dispatch(deleteCartHandler(selectedItem.id));
      const _temp = [...cartList];
      const newObject = JSON.parse(JSON.stringify(_temp));
      const filtered = newObject.filter((item) => item.id !== selectedItem.id);
      dispatch(setCartList(filtered));
      dispatch(setSelectedItems(filtered));

      onDeleteModalClose();
    }
  };

  const handleAddToWishlistItem = (item) => {
    dispatch(addToWishlistHandler(item));
    toast({
      title: 'Product added to wishlist',
      status: 'success',
      duration: 2000,
      isClosable: true,
      onCloseComplete: () => navigate('/wishlist'),
    });
  };

  const onShipping = (item) => {
    setSelectedItem(item);
    onShippingOpen();
  };

  const cartHandler = (data) => {
    try {
      dispatch(setProductId(data.product_unique_id));
      setProductData(data);
      onOpenCart();
    } catch (error) {}
  };

  const calculateTotal = (price, quantity) => price * quantity;

  useEffect(() => {
    if (selectedItem?.id) {
      const payload = {
        id: selectedItem?.product_id,
        country: country,
        currency: currency,
        sku_id: selectedItem?.sku_id,
        quantity: selectedItem?.quantity || 1,
      };
      dispatch(fetchShipmentList(payload));
    }
  }, [country, selectedItem, currency]);

  const handleSelectItem = (item) => {};

  const handleSelectAll = (e) => {
    const _temp = [...cartList];
    const newObject = JSON.parse(JSON.stringify(_temp));
    newObject.forEach((el) => {
      el.selected = e.target.checked;
    });
    dispatch(setCartList(newObject));
    if (!e.target.checked) {
      dispatch(setSelectedItems([]));
    } else {
      dispatch(setSelectedItems(newObject));
    }
  };

  const getProductPricesByIds = useCallback(
    async (is_Id) => {
      const object = {
        user_id: is_Id,
        cart_id: selectedItems.map((item) => item.id),
      };
      dispatch(getDataProductSummary(object)).then((res) => {
        if (res.status === 200) {
          setPriceDetails(res.data);
        }
      });
    },
    [dispatch, selectedItems]
  );

  useEffect(() => {
    if (selectedItems?.length > 0 && (guestInfo?.id || userData?.id)) {
      const userId = userData?.id || guestInfo?.id;
      getProductPricesByIds(userId);
    } else {
      setPriceDetails([]);
    }
  }, [selectedItems, guestInfo?.id, userData?.id, getProductPricesByIds]);

  const handleApplyCoupon = async () => {
    try {
      const payload = {
        user_id: String(userData?.id ?? guestInfo?.id),
        sub_total:
          priceDetails?.sub_total_amount ?? cartDetails?.sub_total_amount ?? 0,
        discount: priceDetails?.discount ?? cartDetails?.discount ?? 0,
        shipping:
          priceDetails?.shipment_amount ?? cartDetails?.shipment_amount ?? 0,
        total: priceDetails?.total_amount ?? cartDetails?.total_amount ?? 0,
        coupon_code: appliedCoupon,
      };

      const response = await dispatch(applyCouponHandler(payload));
      console.log(response, 'response');
      const responseData = response.data.data;
      if (response?.status === 200 || responseData?.statusCode === true) {
        dispatch(setCoupon(appliedCoupon));
        dispatch(setUpdatedCartDetails(responseData));
        dispatch(setVoucherData({ ...responseData, code: appliedCoupon }));
        toast({
          title: 'Success',
          description: response.data?.message || 'Your coupon was applied!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setIsCouponApplied(true);
      } else {
        toast({
          title: 'Failed',
          description:
            response?.data?.message ||
            'An error occurred while applying the coupon',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Failed to apply coupon',
        description:
          error.response?.data?.message || 'An unexpected error occurred.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleRemoveCoupon = async () => {
    try {
      await dispatch(fetchCartList(userData?.id || guestInfo?.id));
      setAppliedCoupon('');
      setIsCouponApplied(false);
      dispatch(removeCoupon());
      dispatch(setUpdatedCartDetails(null));
      toast({
        title: 'Coupon removed successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Failed to remove coupon',
        description:
          error.message || 'An error occurred while removing the coupon',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    document.body.classList.add('cart-page-mobile');
    return () => {
      document.body.classList.remove('cart-page-mobile');
    };
  }, []);

  const isDiscountMoreThanTotal = () => {
    if (updatedCartDetails?.discount > priceDetails?.total_amount) {
      return true;
    }
    return false;
  };

  return (
    <div style={{ paddingBottom: fromHeader ? '0' : '20px' }}>
      {cartList?.length === 0 && (
        <Flex
          flexDir={'column'}
          gap={'10px'}
          justify={'center'}
          align={'center'}
          p='50px'
          minH='50vh'
        >
          <Text fontSize={'24px'}>Your cart is empty!</Text>
          <Text>Add items to it now.</Text>
          <Button variant='primary' onClick={() => navigate('/')}>
            Shop Now
          </Button>
        </Flex>
      )}

      {cartList?.length > 0 && (
        <Box pt='22px' m='0 15px'>
          {!isSmallerThan767 && !fromHeader && (
            <Checkbox
              isChecked={selectedItems.some((item) => item.selected)}
              onChange={handleSelectAll}
              mb='15px'
              fontSize='20px'
              fontWeight={700}
              color='#666666'
              ml={isSmallerThan767 ? '5px' : '1.5rem'}
            >
              {selectedItems.length === 0
                ? 'Select All Items'
                : `Selected Products: ${selectedItems.length}`}
            </Checkbox>
          )}

          <Flex
            direction={{ base: 'column', lg: 'row' }}
            gap='20px'
            wrap={{ base: 'wrap', lg: 'nowrap' }}
            alignItems='flex-start'
          >
            <Flex direction='column' minW='78%' mb={{ base: '100px', md: '0' }}>
              <Container
                className='ownContainer_box'
                minW='78%'
                maxW='100%'
                p='1px'
                borderRadius='0.625rem'
                flexGrow={1}
              >
                <Box
                  className={styles.box + ' ' + 'resp_tbl_box'}
                  overflowX='auto'
                  borderRadius='0 0 0.625rem 0.625rem'
                >
                  <Table className={styles.simple_table + ' ' + 'res_tbl_tdx'}>
                    <Tbody>
                      {cartList.map((item, index) => (
                        <Flex
                          key={index}
                          borderRadius='8px'
                          mb='10px'
                          bg='white'
                          border={fromHeader ? '0' : '1px solid #E3E3E3'}
                        >
                          <Td
                            w='70%'
                            p={
                              isSmallerThan767 || fromHeader
                                ? '5px'
                                : '10px 22px'
                            }
                            ml={fromHeader ? '8px' : '0'}
                          >
                            <Flex gap={'1rem'}>
                              <Checkbox
                                isChecked={item.selected}
                                onChange={(e) => {
                                  const _temp = [...cartList];
                                  const newObject = JSON.parse(
                                    JSON.stringify(_temp)
                                  );
                                  // through map
                                  newObject[index].selected = e.target.checked;
                                  dispatch(setCartList(newObject));

                                  const _filterd = newObject.filter(
                                    (item) => item.selected
                                  );
                                  dispatch(setSelectedItems(_filterd));

                                  console.log(_filterd, 'filterd');
                                }}
                              />
                              <Image
                                onClick={() => {
                                  if (isSmallerThan767) {
                                    navigate(
                                      `/product/details/${item?.product_unique_id}?sku_id=${item?.sku_id}`
                                    );
                                  } else {
                                    cartHandler(item);
                                  }
                                }}
                                borderRadius={fromHeader ? '4px' : '15px'}
                                height={fromHeader ? '35px' : '90px'}
                                width={fromHeader ? '40px' : '110px'}
                                alignSelf='center'
                                src={
                                  item?.image ||
                                  require('../../../assets/img/product/no-image.png')
                                }
                              />
                              <Flex
                                flexDir={'column'}
                                onClick={() => cartHandler(item)}
                              >
                                <Tooltip label={item?.name}>
                                  <Text
                                    fontWeight={600}
                                    fontSize={fromHeader ? '12px' : 14}
                                    maxW={fromHeader ? '213px' : '450px'}
                                    className={
                                      styles.product_name +
                                      ' ' +
                                      'commonFont_ship'
                                    }
                                  >
                                    {item?.name}
                                  </Text>
                                </Tooltip>
                                {item?.attributes?.map((attr, idx) => (
                                  <Flex gap={'5px'} key={idx}>
                                    <Text
                                      fontSize={
                                        fromHeader ? '10px' : '0.813rem'
                                      }
                                      fontWeight={500}
                                      className='commonF2_ship'
                                    >
                                      {attr?.name} :
                                    </Text>
                                    <Text
                                      color='gray'
                                      fontSize='0.813rem'
                                      fontWeight={500}
                                      className='commonF2_ship'
                                    >
                                      {attr?.value}
                                    </Text>
                                  </Flex>
                                ))}
                                {/* <Text fontWeight={700} color={"#000"}>
                                {`${currency} ${item.price}`}
                              </Text> */}
                                <Flex
                                  gap={'5px'}
                                  direction={{ base: 'column', md: 'row' }} // Column on mobile, row on web
                                  alignItems={{
                                    base: 'flex-start',
                                    md: 'center',
                                  }}
                                >
                                  <Box w='auto'>
                                    <Text
                                      className='commonF2_ship'
                                      fontWeight={700}
                                      color={'#000'}
                                      whiteSpace='nowrap'
                                      fontSize={
                                        fromHeader ? '12px' : '0.813rem'
                                      }
                                    >
                                      {`${currency} ${calculateTotal(
                                        formatePrice(item?.price),
                                        item?.quantity
                                      ).toLocaleString('en-US')}`}
                                    </Text>
                                  </Box>
                                  <Badge
                                    color='#F41106'
                                    maxW='105px'
                                    className='commonF2_ship'
                                    bg='#FFDEDC'
                                    borderRadius='5px'
                                  >
                                    {calculateDiscountPercentage(
                                      item?.mrp,
                                      item?.price
                                    )}
                                    % {fromHeader ? 'off' : 'discount'}{' '}
                                  </Badge>
                                </Flex>
                                <Flex gap={'5px'} className='disp_max_none'>
                                  {item.shipment === '0' ||
                                  item.shipment === '0.00' ? (
                                    <Text
                                      fontSize={
                                        fromHeader ? '10px' : '0.813rem'
                                      }
                                      fontWeight={600}
                                      color='#249B3E'
                                    >
                                      Free Shipping
                                    </Text>
                                  ) : (
                                    <>
                                      <Text
                                        fontSize='0.813rem'
                                        fontWeight={500}
                                      >
                                        Shipping :
                                      </Text>
                                      <Text
                                        color='gray'
                                        fontSize='0.813rem'
                                        fontWeight={500}
                                      >
                                        {currency} {item?.shipment}
                                      </Text>
                                    </>
                                  )}
                                </Flex>
                              </Flex>
                            </Flex>
                          </Td>
                          <Td
                            w='30%'
                            display='flex'
                            flexDirection='column'
                            alignItems='flex-end'
                            justifyContent='center'
                            gap='10px'
                          >
                            <Flex
                              gap={fromHeader ? '0' : '10px'}
                              mr='-10px'
                              className='gepCls_cart'
                            >
                              {!fromHeader && (
                                <>
                                  <IconButton
                                    onClick={() => {
                                      const _temp = {
                                        ...item,
                                        quantity: qty,
                                      };
                                      onShipping(_temp);
                                    }}
                                    aria-label='Shipment'
                                    icon={
                                      <Image
                                        src={boxIcon}
                                        alt='Shipment Icon'
                                        boxSize={fromHeader ? '15px' : '20px'}
                                      />
                                    }
                                    variant={'outline'}
                                    border='none'
                                    fontSize={fromHeader ? '15px' : '20px'}
                                  />
                                  <IconButton
                                    onClick={() =>
                                      handleAddToWishlistItem(item)
                                    }
                                    aria-label='Add to wishlist'
                                    icon={
                                      <Image
                                        src={heartIcon}
                                        alt='wishlist Icon'
                                        boxSize={fromHeader ? '15px' : '20px'}
                                      />
                                    }
                                    variant={'outline'}
                                    border='none'
                                    fontSize={fromHeader ? '15px' : '20px'}
                                  />
                                </>
                              )}
                              <IconButton
                                onClick={() => {
                                  handleDeleteClick(item);
                                }}
                                aria-label='Delete item'
                                icon={
                                  <Image
                                    src={dustbinIcon}
                                    alt='Delete Icon'
                                    boxSize={fromHeader ? '15px' : '20px'}
                                  />
                                }
                                variant={'outline'}
                                border='none'
                                fontSize={fromHeader ? '15px' : '20px'}
                              />
                            </Flex>
                            <Box
                              w='80px'
                              display='flex'
                              justifyContent='center'
                            >
                              <NumberGroupInput
                                fromHeader={fromHeader}
                                value={item?.quantity}
                                maxQuantity={item?.attributes[0].avail_quantity}
                                onChange={(value) =>
                                  handleQuantityChange(item, value)
                                }
                              />
                            </Box>
                          </Td>
                        </Flex>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              </Container>

              {expiredCartList?.length !== 0 && (
                <>
                  <Text
                    mt='15px'
                    mb='5px'
                    fontSize='18px'
                    fontWeight={600}
                    color='#666666'
                    ml={fromHeader ? '5px' : '1.5rem'}
                  >
                    Expired : {`(${expiredCartList?.length})`}
                  </Text>
                  <Box
                    className={styles.box + ' ' + 'resp_tbl_box'}
                    overflowX='auto'
                    borderRadius='0 0 0.625rem 0.625rem'
                  >
                    <Table
                      className={styles.simple_table + ' ' + 'res_tbl_tdx'}
                    >
                      <Tbody>
                        {expiredCartList?.map((item, index) => (
                          <Flex
                            key={index}
                            borderRadius='8px'
                            mb='10px'
                            bg='white'
                            border={fromHeader ? '0' : '1px solid #E3E3E3'}
                          >
                            <Td
                              w='70%'
                              p={
                                isSmallerThan767 || fromHeader
                                  ? '5px'
                                  : '10px 22px'
                              }
                            >
                              <Flex gap={'1rem'}>
                                <Checkbox
                                  isChecked={item.selected}
                                  isDisabled={item?.is_stock === 0}
                                  onChange={(e) => {
                                    const _temp = [...cartList];
                                    const newObject = JSON.parse(
                                      JSON.stringify(_temp)
                                    );
                                    // through map
                                    newObject[index].selected =
                                      e.target.checked;
                                    dispatch(setCartList(newObject));

                                    const _filterd = newObject.filter(
                                      (item) => item.selected
                                    );
                                    dispatch(setSelectedItems(_filterd));

                                    console.log(_filterd, 'filterd');
                                  }}
                                />
                                <Image
                                  cursor={
                                    item?.is_stock === 1
                                      ? 'pointer'
                                      : 'not-allowed'
                                  }
                                  // onClick={() => {
                                  //   if (isSmallerThan767) {
                                  //     navigate(
                                  //       `/product/details/${item?.product_unique_id}?sku_id=${item?.sku_id}`
                                  //     );
                                  //   } else {
                                  //     cartHandler(item);
                                  //   }
                                  // }}
                                  borderRadius={fromHeader ? '4px' : '15px'}
                                  height={fromHeader ? '35px' : '90px'}
                                  width={fromHeader ? '40px' : '110px'}
                                  alignSelf='center'
                                  src={
                                    item?.image ||
                                    require('../../../assets/img/product/no-image.png')
                                  }
                                />
                                <Flex
                                  flexDir={'column'}
                                  pointerEvents={
                                    item?.is_stock === 0 ? 'auto' : 'none'
                                  }
                                  opacity={item?.is_stock === 0 ? 0.5 : 1}
                                  cursor={
                                    item?.is_stock === 1
                                      ? 'pointer'
                                      : 'not-allowed'
                                  }
                                  // onClick={() => cartHandler(item)}
                                >
                                  <Tooltip label={item?.name}>
                                    <Text
                                      fontWeight={600}
                                      fontSize={fromHeader ? '12px' : 14}
                                      maxW={fromHeader ? '213px' : '450px'}
                                      className={
                                        styles.product_name +
                                        ' ' +
                                        'commonFont_ship'
                                      }
                                    >
                                      {item?.name}
                                    </Text>
                                  </Tooltip>
                                  {item?.attributes?.map((attr, idx) => (
                                    <Flex gap={'5px'} key={idx}>
                                      <Text
                                        fontSize={
                                          fromHeader ? '10px' : '0.813rem'
                                        }
                                        fontWeight={500}
                                        className='commonF2_ship'
                                      >
                                        {attr?.name} :
                                      </Text>
                                      <Text
                                        color='gray'
                                        fontSize='0.813rem'
                                        fontWeight={500}
                                        className='commonF2_ship'
                                      >
                                        {attr?.value}
                                      </Text>
                                    </Flex>
                                  ))}
                                  <Flex
                                    gap={'5px'}
                                    direction={{ base: 'column', md: 'row' }} // Column on mobile, row on web
                                    alignItems={{
                                      base: 'flex-start',
                                      md: 'center',
                                    }}
                                  >
                                    <Box w='auto'>
                                      <Text
                                        className='commonF2_ship'
                                        fontWeight={700}
                                        color={'#000'}
                                        whiteSpace='nowrap'
                                        fontSize={
                                          fromHeader ? '12px' : '0.813rem'
                                        }
                                      >
                                        {`${currency} ${calculateTotal(
                                          formatePrice(item?.price),
                                          item?.quantity
                                        ).toLocaleString('en-US')}`}
                                      </Text>
                                    </Box>
                                    <Badge
                                      color='#F41106'
                                      maxW='105px'
                                      className='commonF2_ship'
                                      bg='#FFDEDC'
                                      borderRadius='5px'
                                    >
                                      {calculateDiscountPercentage(
                                        item?.mrp,
                                        item?.price
                                      )}
                                      % {fromHeader ? 'off' : 'discount'}{' '}
                                    </Badge>
                                  </Flex>
                                  <Flex gap={'5px'} className='disp_max_none'>
                                    {item.shipment === '0' ||
                                    item.shipment === '0.00' ? (
                                      <Text
                                        fontSize={
                                          fromHeader ? '10px' : '0.813rem'
                                        }
                                        fontWeight={600}
                                        color='#249B3E'
                                      >
                                        Free Shipping
                                      </Text>
                                    ) : (
                                      <>
                                        <Text
                                          fontSize='0.813rem'
                                          fontWeight={500}
                                        >
                                          Shipping :
                                        </Text>
                                        <Text
                                          color='gray'
                                          fontSize='0.813rem'
                                          fontWeight={500}
                                        >
                                          {currency} {item?.shipment}
                                        </Text>
                                      </>
                                    )}
                                  </Flex>
                                </Flex>
                              </Flex>
                            </Td>
                            <Td
                              w='30%'
                              display='flex'
                              flexDirection='column'
                              alignItems='flex-end'
                              justifyContent='center'
                              gap='10px'
                            >
                              <Flex
                                gap={'10px'}
                                mr='-10px'
                                className='gepCls_cart'
                              >
                                {!fromHeader && (
                                  <>
                                    <IconButton
                                      // onClick={() => {
                                      //   const _temp = {
                                      //     ...item,
                                      //     quantity: qty,
                                      //   };
                                      //   onShipping(_temp);
                                      // }}
                                      isDisabled={item?.is_stock === 0}
                                      aria-label='Shipment'
                                      icon={
                                        <Image
                                          src={boxIcon}
                                          alt='Shipment Icon'
                                          boxSize={fromHeader ? '15px' : '20px'}
                                        />
                                      }
                                      variant={'outline'}
                                      border='none'
                                      fontSize={fromHeader ? '15px' : '20px'}
                                    />
                                    <IconButton
                                      // onClick={() => handleAddToWishlistItem(item)}
                                      isDisabled={item?.is_stock === 0}
                                      aria-label='Add to wishlist'
                                      icon={
                                        <Image
                                          src={heartIcon}
                                          alt='wishlist Icon'
                                          boxSize={fromHeader ? '15px' : '20px'}
                                        />
                                      }
                                      variant={'outline'}
                                      border='none'
                                      fontSize={fromHeader ? '15px' : '20px'}
                                    />
                                  </>
                                )}
                                <IconButton
                                  onClick={() => {
                                    handleDeleteClick(item);
                                  }}
                                  aria-label='Delete item'
                                  icon={
                                    <Image
                                      src={dustbinIcon}
                                      alt='Delete Icon'
                                      boxSize={fromHeader ? '15px' : '20px'}
                                    />
                                  }
                                  variant={'outline'}
                                  border='none'
                                  fontSize={fromHeader ? '15px' : '20px'}
                                />
                              </Flex>
                              <Box
                                w='80px'
                                display='flex'
                                justifyContent='center'
                                opacity={item?.is_stock === 0 ? 0.6 : 1}
                                pointerEvents={
                                  item?.is_stock === 0 ? 'none' : 'auto'
                                }
                                cursor='not-allowed'
                              >
                                <NumberGroupInput
                                  fromHeader={fromHeader}
                                  value={item?.quantity}
                                  isDisabled={item?.is_stock === 0}
                                  maxQuantity={
                                    item?.attributes[0].avail_quantity
                                  }
                                  onChange={(value) => {
                                    if (item?.is_stock !== 0) {
                                      handleQuantityChange(item, value);
                                    }
                                  }}
                                />
                              </Box>
                            </Td>
                          </Flex>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                </>
              )}
            </Flex>

            {!isSmallerThan767 && !fromHeader && (
              <Box
                className={styles.box + ' ' + 'contailer_xytl'}
                // bg='#666666'
                p='1px'
                minW='22%'
                borderRadius='0.625rem'
                flexGrow={1}
              >
                <Box
                  bg='white'
                  p='10px'
                  borderRadius='0.625rem'
                  border='1px solid #E3E3E3'
                >
                  <Text
                    mt='5px'
                    fontSize='18px'
                    fontWeight={700}
                    textAlign='start'
                  >
                    Summary
                  </Text>
                  <Flex mt='20px' flexDir={'column'} gap={'.70rem'}>
                    <Flex justify={'space-between'} align={'center'}>
                      <Text color='#8c9097' fontSize='13px'>
                        Sub Total
                      </Text>
                      <Text fontSize='14px'>
                        {updatedCartDetails?.sub_total_amount
                          ? updatedCartDetails?.sub_total_amount
                          : priceDetails?.sub_total_amount
                          ? priceDetails?.sub_total_amount
                          : '0.00'}
                      </Text>
                    </Flex>
                    <Flex justify={'space-between'} align={'center'}>
                      <Text color='#8c9097' fontSize='13px'>
                        Discount
                      </Text>
                      <Text color={'#26bf94'} fontSize='14px'>
                        {updatedCartDetails?.discount
                          ? updatedCartDetails?.discount
                          : priceDetails?.discount
                          ? priceDetails?.discount
                          : '0.00'}
                      </Text>
                    </Flex>
                    <Flex justify={'space-between'} align={'center'}>
                      <Text color='#8c9097' fontSize='13px'>
                        Delivery Charges
                      </Text>
                      <Text fontSize='14px'>
                        {updatedCartDetails?.shipment_amount
                          ? updatedCartDetails?.shipment_amount
                          : priceDetails?.shipment_amount
                          ? priceDetails?.shipment_amount
                          : '0.00'}
                      </Text>
                    </Flex>
                    <Flex justify={'space-between'} align={'center'}>
                      <Text color='#8c9097' fontSize='13px'>
                        Total :
                      </Text>
                      <Text fontWeight={600} fontSize='14px'>
                        {updatedCartDetails?.total_amount
                          ? isDiscountMoreThanTotal()
                            ? 0
                            : updatedCartDetails?.total_amount
                          : priceDetails?.total_amount
                          ? priceDetails?.total_amount
                          : '0.00'}
                      </Text>
                    </Flex>
                  </Flex>

                  <Box mt={4}>
                    <Flex
                      justifyContent={'flex-start'}
                      gap={'5px'}
                      alignItems={'center'}
                    >
                      <Icon as={RiBox3Line} boxSize={4} color='gray.500' />
                      <Text fontWeight='600' fontSize='14px'>
                        Fast delivery
                      </Text>
                    </Flex>
                  </Box>
                  <Box mt={4}>
                    <Flex
                      justifyContent={'flex-start'}
                      gap={'5px'}
                      alignItems={'center'}
                    >
                      <Icon as={AiOutlineSafety} boxSize={4} color='gray.500' />
                      <Text fontWeight='600' fontSize='14px'>
                        Security & Privacy
                      </Text>
                    </Flex>
                    <Stack mt={2} spacing={1} marginLeft='25px'>
                      <Text fontSize='11px' mb={2} color='gray.500'>
                        Safe payments: We do not share your personal details
                        with any third parties without your consent.
                      </Text>
                      <Text fontSize='11px' color='gray.500'>
                        Secure personal details: We protect your privacy and
                        keep your personal details safe and secure.
                      </Text>
                    </Stack>
                  </Box>
                  <Box mt={4}>
                    <Flex
                      justifyContent={'flex-start'}
                      gap={'5px'}
                      alignItems={'center'}
                    >
                      <Icon as={GiThreeLeaves} boxSize={4} color='gray.500' />
                      <Text fontWeight='600' fontSize='14px'>
                        Buyer protection
                      </Text>
                    </Flex>
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='column'
                    maxW='400px'
                    mt='20px'
                  >
                    <Box display='flex' alignItems='center' w='100%'>
                      <Input
                        placeholder='Coupon Code'
                        flex='1'
                        borderRightRadius='0'
                        width='100%'
                        border='#e9edf6 1px solid'
                        value={coupon}
                        onChange={(e) => setAppliedCoupon(e.target.value)}
                        disabled={isCouponApplied}
                      />
                      <Button
                        variant='secondary'
                        color='black'
                        maxW='150px'
                        borderLeftRadius='0'
                        bg={isCouponApplied ? '#E6D9F4' : 'initial'}
                        border={
                          isCouponApplied
                            ? '1px solid #8A2BE2'
                            : '1px solid black'
                        }
                        onClick={
                          isCouponApplied
                            ? handleRemoveCoupon
                            : handleApplyCoupon
                        }
                      >
                        {isCouponApplied ? 'Remove' : 'Apply'}
                      </Button>
                    </Box>
                  </Box>
                  {cartList.filter((item) => item.is_stock === 0).length >
                    0 && (
                    <Text style={{ fontSize: 'small', color: 'red' }}>
                      <br />
                      {
                        cartList.filter((item) => item.is_stock === 0).length
                      }{' '}
                      products are out of stock, <br />
                      please remove product and proceed
                    </Text>
                  )}
                  <Button
                    w='100%'
                    mt='20px'
                    variant={'primary'}
                    onClick={() => {
                      if (isDiscountMoreThanTotal()) {
                        navigate(
                          `/create/order?discount=${usehashParam('all')}`
                        );
                      } else {
                        navigate('/create/order');
                      }
                    }}
                    isDisabled={selectedItems.length === 0}
                  >
                    Proceed to checkout
                  </Button>
                  <Button
                    w='100%'
                    mt='20px'
                    mb='25px'
                    variant={'secondary'}
                    onClick={() => {
                      navigate('/product/listing');
                    }}
                  >
                    Continue Shopping
                  </Button>
                </Box>
              </Box>
            )}
          </Flex>
        </Box>
      )}

      {/* new design according to mobile device only */}
      {(isSmallerThan767 || fromHeader) && cartList?.length > 0 && (
        <SummaryMobile
          isOpenSummary={isOpenSummary}
          setIsOpenSummary={setIsOpenSummary}
          cartItems={cartList}
          priceDetails={priceDetails}
          selectedItems={selectedItems}
          handleSelectAll={handleSelectAll}
          fromHeader={fromHeader}
        />
      )}

      <DeleteCartModal
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        confirmDelete={confirmDelete}
      />

      <ShippingModal
        fromCart
        isOpen={isShippingOpen}
        onClose={onShippingClose}
        product={selectedItem}
      />

      <Modal width='95%' p='0 30px' isOpen={isOpenCart} onClose={onCloseCart}>
        <ProductDetail
          fromHome
          cart_sku_id={productData?.sku_id}
          onClose={onCloseCart}
          fromCart
        />
      </Modal>
    </div>
  );
};

export default Cart;
