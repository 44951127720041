import { Box, Button, Center, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from '@chakra-ui/react';
import { Form, Formik } from 'formik';

import { useState } from 'react';
import * as Yup from 'yup';
import FormPasswordInput from '../../components/form-password-input';
import { RiCheckboxCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changePasswordHandler } from '../../redux/userSlice';
import { deleteUser } from '../../services/user-service';
import { v4 as uuidv4 } from 'uuid';
import { setIsLogin } from '../../redux/homeSlice';
import { setGuestInfo } from '../../redux/guestSlice';

const PasswordSetting = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isAuthenticated = localStorage.getItem('token') ? true : false;

  const handleDeleteUser = async () => {
    setIsLoading(true);
    try {
      const response = await deleteUser(userData.id);
      if (response.status === 200) {
        toast({
          title: 'Account deleted successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        localStorage.clear('token');
        localStorage.setItem('isAuthenticated', 'true');
        let guestId = uuidv4();
        localStorage.setItem(
          'guestInfo',
          JSON.stringify({ id: guestId })
        );
        dispatch(setGuestInfo(guestId));
        dispatch(setIsLogin(false));
        window.location.href = '/';
      } else {
        throw new Error('Failed to delete account');
      }
    } catch (error) {
      toast({
        title: 'Failed to delete account.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  return (
    <Box p={'32px'} borderRadius={'4px'} border={'1px solid #f3f3f3'}>
      <Text fontWeight={600} fontSize={'16px'} mb='1.63rem'>
        {' '}
        Reset Password
      </Text>
      <Formik
        initialValues={{ pass: '', confirm: '' }}
        validationSchema={Yup.object({
          oldPassword: Yup.string().required(' '),
          pass: Yup.string()
            .required(' ')
            .matches(
              /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,32}$/,
              ' '
            ),
          confirm: Yup.string()
            .oneOf([Yup.ref('pass'), null], 'Passwords do not match.')
            .required(' '),
        })}
        onSubmit={(values) => {
          setIsLoading(true);
          const paylaod = {
            user_id: userData.id,
            current_password: values.oldPassword,
            password: values.pass,
          };
          dispatch(changePasswordHandler(paylaod)).then((res) => {
            if (res.status === 200) {
              toast({
                title: 'Password changed successfully',
                status: 'success',
              });
              localStorage.clear();
              window.location.href = '/';
            } else {
              toast({
                title: 'Password change failed',
                status: 'error',
              });
            }
          });
          setIsLoading(false);
        }}
        validateOnMount
      >
        {({ isValid, values }) => (
          <Flex as={Form} flexDir={'column'} gap={'30px'} w={{ base: 'auto', md: '400px' }}>
            <Flex flexDir={'column'} gap={'20px'}>
              <FormPasswordInput
                name='oldPassword'
                label='Current Password'
                placeholder='Current Password'
              />
              <FormPasswordInput
                name='pass'
                label='New Password'
                placeholder='New Password'
              />

              <Flex
                flexDir={'column'}
                gap={'5px'}
                textStyle={'body-rg'}
                color={'text.secondary.100'}
              >
                <Text my={'5px'}>Passwords must:</Text>

                <PasswordRequirement
                  isMet={values.pass.match(/^.{8,32}$/)}
                  label={'Be between 8 and 32 characters'}
                />
                <PasswordRequirement
                  isMet={values.pass.match(/^(?=.*?[A-Z])/)}
                  label={'Contain atleast 1 uppercase letter'}
                />
                <PasswordRequirement
                  isMet={values.pass.match(/^(?=.*?[0-9])/)}
                  label={'Contain atleast 1 number'}
                />
                <PasswordRequirement
                  isMet={values.pass.match(/^(?=.*?[#?!@$%^&*-])/)}
                  label={'Contain atleast 1 special character'}
                />
              </Flex>
            </Flex>

            <FormPasswordInput
              name='confirm'
              label='Confirm Password'
              placeholder='Confirm Password'
            />

            <Flex gap={'10px'} w={'100%'} pb={{ base: '20px', md: '0px' }}>
              <Button
                variant={'primary'}
                type='submit'
                isDisabled={!isValid}
                w={'55%'}
                isLoading={isLoading}
                loadingText='Please wait...'

              >
                Update Password
              </Button>
            </Flex>

            {isAuthenticated &&
              <Button
                variant="primary"
                colorScheme="red"
                w="100%"
                mb={{ base: '50px', md: '0px' }}
                onClick={() => setIsModalOpen(true)}
              >
                Delete Account
              </Button>
            }
          </Flex>
        )}
      </Formik>


      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Your Account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>When you use Kwelibuy, we will store your data to enable you to conclude transactions with sellers, as well as to provide you related services. If you choose to delete your personal account, we will delete your personal data generated on Kwelibuy, and we will no longer be able to provide services to you.
              <br />The request to delete your account is final and cannot be undone. Please consider carefully before proceeding.</Text>
            <br />
            <strong>
              This operation may invalidate other requests you could have applied for.
            </strong>
            <br />
            <br />
            <Text>
              Before deleting your account, we will evaluate the status of your account. We will carefully review your deletion request, including taking into account whether deleting your existing orders on Kwelibuy may affect your legitimate rights and interests or legal obligations that you need to comply with.
            </Text>
          </ModalBody>
          <ModalFooter
            style={{
              marginTop: '0px',
              paddingBottom: window.innerWidth < 400 ? '60px' : '20px',
            }}>
            <Button variant="outline" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => handleDeleteUser()}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Box>
  );
};

export default PasswordSetting;

const PasswordRequirement = ({ label, isMet }) => {
  return (
    <Flex alignItems={'center'} w={'300px'} gap={'10px'}>
      <Center w={'20px'}>
        {isMet ? (
          <RiCheckboxCircleLine color='green' />
        ) : (
          <RiCloseCircleLine color='red' />
        )}
      </Center>

      <Flex fontSize={'14px'} pt={'3px'} textAlign={'left'}>
        {' '}
        {label}
      </Flex>
    </Flex>
  );
};
