import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { lazy, Suspense, useCallback, useState, useEffect } from 'react';
import styles from './profile.module.scss';
import PasswordSetting from './password-setting';
import PersonalInformation from './personal-information';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddressHandler, setAddressList } from '../../redux/userSlice';
import { fetchAddress } from '../../services/user-service';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import Notifications from './notifications';

const AddressList = lazy(() => import('./address-list'));

const Profile = () => {
  const dispatch = useDispatch();
  const { userData, addressList } = useSelector((state) => state.user);

  // Retrieve the tab index from localStorage or default to 0
  const getStoredTabIndex = () => {
    const storedIndex = localStorage.getItem('activeTabIndex');
    return storedIndex ? parseInt(storedIndex, 10) : 0;
  };

  const [tabIndex, setTabIndex] = useState(getStoredTabIndex); // State to control the active tab index

  const fetchAddressList = useCallback(async () => {
    const response = await fetchAddress(userData?.id);
    if (response.status === 200) {
      dispatch(setAddressList(response.data.data));
    }
  }, [dispatch, userData?.id]);

  useEffectOnce(() => {
    if (userData?.id && addressList.length === 0) fetchAddressList();
  });

  // Store the tab index in localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('activeTabIndex', tabIndex);
  }, [tabIndex]);

  return (
    <Box className={styles.main}>
      <Tabs
        index={tabIndex} // Control active tab
        onChange={(index) => setTabIndex(index)} // Update active tab index on change
      >
        <TabList borderBottom={'1px solid #f3f3f3'}>
          <Tab>Personal Information</Tab>
          <Tab>Account Settings</Tab>

          <Tab
          // display={{ base: 'none', md: 'block' }}
          >Address</Tab>

          <Tab >Notifications</Tab>
        </TabList>
        <TabPanels >
          <TabPanel>
            <PersonalInformation />
          </TabPanel>
          <TabPanel>
            <PasswordSetting />
          </TabPanel>
          <TabPanel>
            <Suspense fallback={<div>Loading...</div>}>
              <AddressList tabIndex={tabIndex} setTabIndex={setTabIndex} /> {/* Pass tabIndex and setTabIndex */}
            </Suspense>
          </TabPanel>
          <TabPanel>
            <Notifications />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Profile;
