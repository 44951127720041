import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  VStack,
  Text,
  Flex,
} from "@chakra-ui/react";
import NotificationCard from "./notification-card";
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { fetchNotificationsHandler, readNotificationsHandler } from "../../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";

const NotificationList = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state?.user);
  const hasDispatched = useRef(false);
  const { notificationsList } = useSelector((state) => state?.user);

  // useEffect(() => {
  //   console.log('useEffect triggered');
  //   console.log('userData?.id:', userData?.id);
  //   console.log('hasDispatched.current:', hasDispatched.current);
  
  //   if (userData?.id && !hasDispatched.current) {
  //     console.log('Dispatching fetch and read notifications...');
  //     dispatch(fetchNotificationsHandler(userData?.id));
  //     dispatch(readNotificationsHandler(userData?.id));
  //     hasDispatched.current = true; 
  //     console.log('hasDispatched.current set to true');
  //   }
  // }, [dispatch, userData?.id]); 
  
  return (
    <Box minH='50vh'>
      <Flex
        flexDir={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
        p="25px 20px 0px 20px"
      >
        <Text fontSize="20px" fontWeight="bold">
          Notifications
        </Text>
        <Flex alignItems="center" gap="10px">
          <Text color="#E6533C" fontSize="14px">
            Pages
          </Text>
          <RiArrowRightDoubleFill color="#E6533C" />
          <Text fontWeight="bold" fontSize="14px">
            Notifications
          </Text>
        </Flex>
      </Flex>

      <Box p={4} m="10px" borderRadius="10px" minH='50vh'>
        {(!notificationsList || notificationsList.length === 0) && (
          <Flex
            flexDir={'column'}
            gap={'10px'}
            justify={'center'}
            align={'center'}
            verticalAlign='center'
            textAlign='center'
            p='50px'
          >
            <Text fontSize={'24px'}>No new Notifications!</Text>
            <Text>Currently You don't have any notifications.</Text>
          </Flex>
        )}

        <Box >
          <Box p={4} m="10px" borderRadius="10px">
            <VStack spacing={4}>
              {notificationsList?.map((notification, index) => (
                <React.Fragment key={notification?.id}>
                  <NotificationCard notification={notification} />
                </React.Fragment>
              ))}
            </VStack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(NotificationList);

